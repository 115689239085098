<template>
  <div>
    <template v-if="!submitted">
      <h1>
        Log på
      </h1>
      <form @submit.prevent="processForm">
        <BaseInput
          name="email"
          v-model="login.email"
          label="E-mail*"
          placeholder="E-mail"
          :hasInput="!!v$.login.email.$model"
          :isValid="!v$.login.email.$invalid"
          :error="loginFailed ? error : v$.login.email.$error ? v$.login.email.$errors[0].$message : ''"
          @blur="v$.login.email.$touch()"
        />
        <BaseInput
          name="password"
          type="password"
          v-model="login.password"
          label="Adgangskode*"
          placeholder="••••••••••••"
          :hasInput="!!v$.login.password.$model"
          :isValid="!v$.login.password.$invalid"
          :error="loginFailed ? error : v$.login.password.$error ? v$.login.password.$errors[0].$message : ''"
          @blur="v$.login.password.$touch()"
        />
        <!-- <p class="pt-4 paragraph-s text-label-error">
          Dit brugernavn eller din adgangskode er forkert. Prøv igen
        </p> -->
        <div v-if="loginFailed" class="card card-error mt-5">
          Dit brugernavn eller din adgangskode er forkert. Prøv igen
        </div>
        <button type="submit" class="button-primary">
          Log på
        </button>
        <div class="flex justify-center">
          <router-link :to="{ name: 'Reset' }" class="textlink">
            Har du glemt dit kodeord?
          </router-link>
        </div>
      </form>
    </template>
    <div v-else-if="loading" class="flex-grow flex items-center justify-center">
      Loader ...
      <Spinner class="ml-3" />
    </div>
    <template v-else-if="success">
      <h1>
        Du er nu logget ind
      </h1>
      <p class="subheading">
        Viderestiller. Hvis der ikke sker noget, så klik på knappen nedenfor.
      </p>
      <a :href="portalUrl" class="button-primary">
        Gå til portalen
      </a>
    </template>
    <div v-else class="card card-error">
      <h6 class="">
        Der er noget galt med dit login
      </h6>
      <p>
        Vores kundeservice er blevet informeret og vil kontakte dig. Hvis du ikke hører fra os, bedes du kontakte vores kundeservice på hackingalarm@skimsafe.dk
      </p>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput'
import Spinner from '@/components/Spinner'
import useVuelidate from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import ky from 'ky'

export default {
  name: 'Login',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    BaseInput,
    Spinner
  },
  data () {
    return {
      login: {
        email: '',
        password: ''
      },
      loginFailed: false,
      error: '',
      submitted: false,
      success: false,
      loading: false,
      // redirectSeconds: 3,
      portalUrl: ''
    }
  },
  validations () {
    return {
      login: {
        email: {
          required: helpers.withMessage('E-mail er påkrævet', required),
          email: helpers.withMessage('E-mailadressen skal være gyldig', email)
        },
        password: {
          required: helpers.withMessage('Adgangskode er påkrævet', required)
        }
      }
    }
  },
  watch: {
    login: {
      handler: function () {
        this.loginFailed = false
        this.error = ''
      },
      deep: true
    }
  },
  methods: {
    async processForm () {
      this.v$.$touch()
      // console.log(this.v$.$invalid)
      if (!this.v$.$invalid) {
        this.submitted = true
        this.loading = true
        try {
          const loginResponse = await ky.post('/api/login', {
            json: {
              ...this.login
            }
          }).json()
          // console.log(loginResponse)
          this.success = loginResponse.success
          // this.loading = false
          if (this.success) {
            // login successfull
            // this.redirectSeconds = 3
            this.portalUrl = loginResponse.url
            // show confirmation, redirecting to portal
            this.loginFailed = false
            // while (this.redirectSeconds > 1) {
            //   await new Promise(resolve => setTimeout(resolve, 1000))
            //   this.redirectSeconds -= 1
            // }
            // console.log('redirect to ', this.portalUrl)
            window.location = this.portalUrl
          } else {
            // login error
            this.loginFailed = true
            this.error = 'Login mislykkedes'
            this.loading = false
            this.submitted = false
          }
        } catch (error) {
          // console.log(error)
          this.loginFailed = true
          this.success = false
          this.submitted = false
        }
      }
    }
  }
}
</script>
